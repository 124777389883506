import laserOriginal1 from '../img/catriges/laser(original)1.jpg';
import laserOriginal2 from '../img/catriges/laser(original)2.jpg';
import laserOriginal3 from '../img/catriges/laser(original)3.jpg';
import laserOriginal4 from '../img/catriges/laser(original)4.jpg';
import laserOriginal5 from '../img/catriges/laser(original)5.jpg';
import laserOriginal6 from '../img/catriges/laser(original)6.jpg';
import laserOriginal7 from '../img/catriges/laser(original)7.png';
import laserOriginal8 from '../img/catriges/laser(original)8.png';
import laserOriginal9 from '../img/catriges/laser(original)9.png';
import laserOriginal10 from '../img/catriges/laser(original)10.png';
import laserOriginal11 from '../img/catriges/laser(original)11.jpg';
import laserOriginal12 from '../img/catriges/laser(original)12.png';
import laserOriginal13 from '../img/catriges/laser(original)13.png';
import laserOriginal14 from '../img/catriges/laser(original)14.jpg';
import laserOriginal15 from '../img/catriges/laser(original)15.png';
import laserOriginal16 from '../img/catriges/laser(original)16.jpg';
import laserOriginal17 from '../img/catriges/laser(original)17.jpg';
import laserOriginal18 from '../img/catriges/laser(original)18.jpg';
import laserOriginal19 from '../img/catriges/laser(original)19.jpeg';
import laserOriginal20 from '../img/catriges/laser(original)20.jpeg';
import laserOriginal21 from '../img/catriges/laser(original)21.jpg';
import laserOriginal22 from '../img/catriges/laser(original)22.jpg';
import laserOriginal23 from '../img/catriges/laser(original)23.jpg';
import laserOriginal24 from '../img/catriges/laser(original)24.jpg';
import laserOriginal25 from '../img/catriges/laser(original)25.jpg';
import laserOriginal26 from '../img/catriges/laser(original)26.jpg';
import laserOriginal27 from '../img/catriges/laser(original)27.jpeg';
import laserOriginal28 from '../img/catriges/laser(original)28.jpg';
import laserOriginal29 from '../img/catriges/laser(original)29.jpg';
import laserOriginal30 from '../img/catriges/laser(original)30.png';
import laserOriginal31 from '../img/catriges/laser(original)31.jpg';
import laserOriginal32 from '../img/catriges/laser(original)32.jpg';
import laserOriginal33 from '../img/catriges/laser(original)33.jpg';
import laserOriginal34 from '../img/catriges/laser(original)34.jpg';
import laserOriginal35 from '../img/catriges/laser(original)35.jpg';
import laserOriginal36 from '../img/catriges/laser(original)36.jpg';
import laserOriginal37 from '../img/catriges/laser(original)37.jpg';
import laserOriginal38 from '../img/catriges/laser(original)38.png';
import laserOriginal39 from '../img/catriges/laser(original)39.png';
import laserOriginal40 from '../img/catriges/laser(original)40.jpg';
import laserOriginal41 from '../img/catriges/laser(original)41.jpg';
import laserOriginal42 from '../img/catriges/laser(original)42.jpg';
import laserOriginal43 from '../img/catriges/laser(original)43.jpg';
import laserOriginal44 from '../img/catriges/laser(original)44.jpg';
import laserOriginal45 from '../img/catriges/laser(original)45.jpg';
import laserOriginal46 from '../img/catriges/laser(original)46.png';
import laserOriginal47 from '../img/catriges/laser(original)47.jpg';
import laserOriginal48 from '../img/catriges/laser(original)48.jpg';
import laserOriginal49 from '../img/catriges/laser(original)49.jpg';
import laserOriginal50 from '../img/catriges/laser(original)50.jpg';
import laserOriginal51 from '../img/catriges/laser(original)51.jpg';
import laserOriginal52 from '../img/catriges/laser(original)52.jpg';
import laserOriginal53 from '../img/catriges/laser(original)53.jpg';
import laserOriginal54 from '../img/catriges/laser(original)54.png';
import laserOriginal55 from '../img/catriges/laser(original)55.jpg';
import laserOriginal56 from '../img/catriges/laser(original)56.jpg';
import laserOriginal57 from '../img/catriges/laser(original)57.jpg';
import laserOriginal58 from '../img/catriges/laser(original)58.jpg';
import laserOriginal59 from '../img/catriges/laser(original)59.jpg';
import laserOriginal60 from '../img/catriges/laser(original)60.jpg';
import laserOriginal61 from '../img/catriges/laser(original)61.jpg';
import laserOriginal62 from '../img/catriges/laser(original)62.jpg';
import laserOriginal63 from '../img/catriges/laser(original)63.png';
import laserOriginal64 from '../img/catriges/laser(original)64.jpg';
import laserOriginal65 from '../img/catriges/laser(original)65.jpg';
import laserOriginal66 from '../img/catriges/laser(original)66.jpg';
import laserOriginal67 from '../img/catriges/laser(original)67.jpg';
import laserOriginal68 from '../img/catriges/laser(original)68.png';
import laserOriginal69 from '../img/catriges/laser(original)69.jpg';
import laserOriginal70 from '../img/catriges/laser(original)70.jpeg';
import laserOriginal71 from '../img/catriges/laser(original)71.jpg';
import laserOriginal72 from '../img/catriges/laser(original)72.jpg';
import laserOriginal73 from '../img/catriges/laser(original)73.jpg';
import laserOriginal74 from '../img/catriges/laser(original)74.jpg';
import laserOriginal75 from '../img/catriges/laser(original)75.jpg';
import laserOriginal76 from '../img/catriges/laser(original)76.jpg';
import laserOriginal77 from '../img/catriges/laser(original)77.jpg';
import laserOriginal78 from '../img/catriges/laser(original)78.jpg';
import laserOriginal79 from '../img/catriges/laser(original)79.jpg';
import laserOriginal80 from '../img/catriges/laser(original)80.jpg';
import laserOriginal81 from '../img/catriges/laser(original)81.jpg';
import laserOriginal82 from '../img/catriges/laser(original)82.jpg';
import laserOriginal83 from '../img/catriges/laser(original)83.jpg';
import laserOriginal84 from '../img/catriges/laser(original)84.jpg';
import laserOriginal85 from '../img/catriges/laser(original)85.jpg';
import laserOriginal86 from '../img/catriges/laser(original)86.jpg';
import laserOriginal87 from '../img/catriges/laser(original)87.jpg';
import laserOriginal88 from '../img/catriges/laser(original)88.jpg';
import laserOriginal89 from '../img/catriges/laser(original)89.png';
import laserOriginal90 from '../img/catriges/laser(original)90.jpg';
import laserOriginal91 from '../img/catriges/laser(original)91.jpg';
import laserOriginal92 from '../img/catriges/laser(original)92.jpg';
import laserOriginal93 from '../img/catriges/laser(original)93.jpg';
import laserOriginal94 from '../img/catriges/laser(original)94.jpg';
import laserOriginal95 from '../img/catriges/laser(original)95.jpg';
import laserOriginal96 from '../img/catriges/laser(original)96.jpg';
import laserOriginal97 from '../img/catriges/laser(original)97.jpg';
import laserOriginal98 from '../img/catriges/laser(original)98.jpg';
import laserOriginal99 from '../img/catriges/laser(original)99.png';
import laserOriginal100 from '../img/catriges/laser(original)100.jpg';
import laserOriginal101 from '../img/catriges/laser(original)101.jpg';
import laserOriginal102 from '../img/catriges/laser(original)102.png';
import laserOriginal103 from '../img/catriges/laser(original)103.jpg';
import laserOriginal104 from '../img/catriges/laser(original)104.jpg';
import laserOriginal105 from '../img/catriges/laser(original)105.png';
import laserOriginal106 from '../img/catriges/laser(original)106.jpg';
import laserOriginal107 from '../img/catriges/laser(original)107.jpg';
import laserOriginal108 from '../img/catriges/laser(original)108.jpg';
import laserOriginal109 from '../img/catriges/laser(original)109.jpg';
import laserOriginal110 from '../img/catriges/laser(original)110.jpg';
import laserOriginal111 from '../img/catriges/laser(original)111.jpg';
import laserOriginal112 from '../img/catriges/laser(original)112.png';
import laserOriginal113 from '../img/catriges/laser(original)113.png';
import laserOriginal114 from '../img/catriges/laser(original)114.jpg';
import laserOriginal115 from '../img/catriges/laser(original)115.jpg';
import laserOriginal116 from '../img/catriges/laser(original)116.jpg';
import laserOriginal117 from '../img/catriges/laser(original)117.png';
import laserOriginal118 from '../img/catriges/laser(original)118.jpg';
import laserOriginal119 from '../img/catriges/laser(original)119.jpg';
import laserOriginal120 from '../img/catriges/laser(original)120.jpg';
import laserOriginal121 from '../img/catriges/laser(original)121.jpg';
import laserOriginal122 from '../img/catriges/laser(original)122.jpg';
import laserOriginal123 from '../img/catriges/laser(original)123.jpg';
import laserAnaloq3 from '../img/catriges/laser(analoq)3.jpg';
import laserAnaloq4 from '../img/catriges/laser(analoq)4.jpeg';
import laserAnaloq5 from '../img/catriges/laser(analoq)5.jpeg';
import laserAnaloq6 from '../img/catriges/laser(analoq)6.jpeg';
import laserAnaloq7 from '../img/catriges/laser(analoq)7.jpg';
import laserAnaloq8 from '../img/catriges/laser(analoq)8.jpg';
import laserAnaloq9 from '../img/catriges/laser(analoq)9.jpg';
import laserAnaloq10 from '../img/catriges/laser(analoq)10.jpg';
import laserAnaloq11 from '../img/catriges/laser(analoq)11.jpg';
import laserAnaloq12 from '../img/catriges/laser(analoq)12.jpeg';
import laserAnaloq13 from '../img/catriges/laser(analoq)13.jpg';
import laserAnaloq14 from '../img/catriges/laser(analoq)14.jpg';
import laserAnaloq15 from '../img/catriges/laser(analoq)15.jpg';
import laserAnaloq16 from '../img/catriges/laser(analoq)16.jpg';
import laserAnaloq17 from '../img/catriges/laser(analoq)17.jpg';
import laserAnaloq18 from '../img/catriges/laser(analoq)18.jpg';
import laserAnaloq19 from '../img/catriges/laser(analoq)19.jpg';
import laserAnaloq20 from '../img/catriges/laser(analoq)20.jpg';
import laserAnaloq21 from '../img/catriges/laser(analoq)21.jpg';
import laserAnaloq22 from '../img/catriges/laser(analoq)22.jpg';
import laserAnaloq23 from '../img/catriges/laser(analoq)23.jpg';
import laserAnaloq24 from '../img/catriges/laser(analoq)24.jpg';
import laserAnaloq25 from '../img/catriges/laser(analoq)25.jpg';
import laserAnaloq26 from '../img/catriges/laser(analoq)26.jpg';
import laserAnaloq27 from '../img/catriges/laser(analoq)27.jpg';
import laserAnaloq28 from '../img/catriges/laser(analoq)28.jpg';
import laserAnaloq29 from '../img/catriges/laser(analoq)29.jpg';
import laserAnaloq30 from '../img/catriges/laser(analoq)30.jpg';
import laserAnaloq31 from '../img/catriges/laser(analoq)31.jpg';
import laserAnaloq34 from '../img/catriges/laser(analoq)34.jpg';
import laserAnaloq35 from '../img/catriges/laser(analoq)35.jpg';
import laserAnaloq36 from '../img/catriges/laser(analoq)36.jpg';

import tubaAnaloq1 from '../img/catriges/tuba(analoq)1.jpg';
import tubaAnaloq2 from '../img/catriges/tuba(analoq)2.jpg';
import tubaAnaloq3 from '../img/catriges/tuba(analoq)3.jpg';
import tubaOrijinal1 from '../img/catriges/tuba(orijinal)1.jpg';
import tubaOrijinal2 from '../img/catriges/tuba(orijinal)2.png';
import tubaOrijinal3 from '../img/catriges/tuba(orijinal)3.jpg';
import tubaOrijinal4 from '../img/catriges/tuba(orijinal)4.jpg';
import tubaOrijinal5 from '../img/catriges/tuba(orijinal)5.jpg';
import tubaOrijinal6 from '../img/catriges/tuba(orijinal)6.jpg';
import tubaOrijinal7 from '../img/catriges/tuba(orijinal)7.jpg';
import tubaOrijinal8 from '../img/catriges/tuba(orijinal)8.jpg';
import tubaOrijinal9 from '../img/catriges/tuba(orijinal)9.jpg';
import tubaOrijinal10 from '../img/catriges/tuba(orijinal)10.jpg';
import tubaOrijinal11 from '../img/catriges/tuba(orijinal)11.jpg';

export const catridgeData = [
    {
        img: laserOriginal1,
        title:'Drum Kartric HP 32A (CF232A)',
        brand:'HP',
        //:'169.50',
        type: 'laser',
        originality: 'orijinal'
    },
    {
        img: laserOriginal2,
        title:'Drum Kartric HP 19A (CF219A)',
        brand:'HP',
        //:'134.50',
        type: 'laser',
        originality: 'orijinal'
    },
    {
        img: laserOriginal3,
        title:'Drum Kartric HP CF34A (CF234A)',
        brand:'HP',
        //:'119.50',
        type: 'laser',
        originality: 'orijinal'
    },
    {
        img: laserOriginal4,
        title:'Kartric Canon 045 BK',
        brand:'Canon',
        //:'129.50',
        type: 'laser',
        originality: 'orijinal'
    },
    {
        img: laserOriginal5,
        title:'Kartric Canon 703',
        brand:'HP',
        //:'110.50',
        type: 'laser',
        originality: 'orijinal'
    },
    {
        img: laserOriginal6,
        title:'Kartric Canon 712',
        brand:'Canon',
        //:'85.50',
        type: 'laser',
        originality: 'orijinal'
    },
    {
        img: laserOriginal7,
        title:'Kartric Canon 718 (BK)',
        brand:'Canon',
        //:'189.00',
        type: 'laser',
        originality: 'orijinal'
    },
    {
        img: laserOriginal8,
        title:'Kartric Canon 718 (CY)',
        brand:'Canon',
        //:'198.20',
        type: 'laser',
        originality: 'orijinal'
    },
    {
        img: laserOriginal9,
        title:'Kartric Canon 718 (MG)',
        brand:'Canon',
        //:'198.20',
        type: 'laser',
        originality: 'orijinal'
    },
    {
        img: laserOriginal10,
        title:'Kartric Canon 718 (YW)',
        brand:'Canon',
        //:'198.20',
        type: 'laser',
        originality: 'orijinal'
    },
    {
        img: laserOriginal11,
        title:'Kartric Canon 719',
        brand:'Canon',
        //:'125.40',
        type: 'laser',
        originality: 'orijinal'
    },
    {
        img: laserOriginal12,
        title:'Kartric Canon 725',
        brand:'Canon',
        //:'105.50',
        type: 'laser',
        originality: 'orijinal'
    },
    {
        img: laserOriginal13,
        title:'Kartric Canon 728',
        brand:'Canon',
        //:'110.50',
        type: 'laser',
        originality: 'orijinal'
    },
    {
        img: laserOriginal14,
        title:'Kartric Canon 731 (CY)',
        brand:'Canon',
        //:'119.30',
        type: 'laser',
        originality: 'orijinal'
    },
    {
        img: laserOriginal15,
        title:'Kartric Canon 731 BK',
        brand:'Canon',
        //:'112.40',
        type: 'laser',
        originality: 'orijinal'
    },
    {
        img: laserOriginal16,
        title:'Kartric Canon 731 MG',
        brand:'Canon',
        //:'119.30',
        type: 'laser',
        originality: 'orijinal'
    },
    {
        img: laserOriginal17,
        title:'Kartric Canon 731 YL',
        brand:'Canon',
        //:'119.30',
        type: 'laser',
        originality: 'orijinal'
    },
    {
        img: laserOriginal18,
        title:'Kartric Canon 737',
        brand:'Canon',
        //:'115.50',
        type: 'laser',
        originality: 'orijinal'
    },
    {
        img: laserOriginal19,
        title:'Kartric Canon E16',
        brand:'Canon',
        //:'125.50',
        type: 'laser',
        originality: 'orijinal'
    },
    {
        img: laserOriginal20,
        title:'Kartric Canon EP27',
        brand:'Canon',
        //:'95.50',
        type: 'laser',
        originality: 'orijinal'
    },
    {
        img: laserOriginal21,
        title:'Kartric Canon FX10',
        brand:'Canon',
        //:'110.50',
        type: 'laser',
        originality: 'orijinal'
    },
    {
        img: laserOriginal22,
        title:'Kartric HP 30A (CF230A)',
        brand:'HP',
        //:'124.50',
        type: 'laser',
        originality: 'orijinal'
    },
    {
        img: laserOriginal23,
        title:'Kartric HP 05A (CE505A)',
        brand:'HP',
        //:'158.00',
        type: 'laser',
        originality: 'orijinal'
    },
    {
        img: laserOriginal24,
        title:'Kartric HP 11A (Q6511A)',
        brand:'HP',
        //:'195.40',
        type: 'laser',
        originality: 'orijinal'
    },
    {
        img: laserOriginal25,
        title:'Kartric HP 12A (Q2612A)',
        brand:'HP',
        //:'115.50',
        type: 'laser',
        originality: 'orijinal'
    },
    {
        img: laserOriginal26,
        title:'Kartric HP 13A (Q2613A)',
        brand:'HP',
        //:'95.40',
        type: 'laser',
        originality: 'orijinal'
    },
    {
        img: laserOriginal27,
        title:'Kartric HP 15A (C7115A)',
        brand:'HP',
        //:'95.50',
        type: 'laser',
        originality: 'orijinal'
    },
    {
        img: laserOriginal28,
        title:'Kartric HP 16A (Q7516A)',
        brand:'HP',
        //:'285.50',
        type: 'laser',
        originality: 'orijinal'
    },
    {
        img: laserOriginal29,
        title:'Kartric HP 17A (CF217A)',
        brand:'HP',
        //:'119.50',
        type: 'laser',
        originality: 'orijinal'
    },
    {
        img: laserOriginal30,
        title:'Kartric HP 207A (W2210A)',
        brand:'HP',
        //:'122.90',
        type: 'laser',
        originality: 'orijinal'
    },
    {
        img: laserOriginal31,
        title:'Kartric HP 216A (W2410A)',
        brand:'HP',
        //:'94.90',
        type: 'laser',
        originality: 'orijinal'
    },
    {
        img: laserOriginal32,
        title:'Kartric HP 26A (CF226A)',
        brand:'HP',
        //:'199.50',
        type: 'laser',
        originality: 'orijinal'
    },
    {
        img: laserOriginal33,
        title:'Kartric HP 26X (CF226X)',
        brand:'HP',
        //:'429.50',
        type: 'laser',
        originality: 'orijinal'
    },
    {
        img: laserOriginal34,
        title:'Kartric HP 29X (C4129X)',
        brand:'HP',
        //:'255.40 ',
        type: 'laser',
        originality: 'orijinal'
    },
    {
        img: laserOriginal35,
        title:'Kartric HP 33A (CF233A)',
        brand:'HP',
        //:'39.00',
        type: 'laser',
        originality: 'orijinal'
    },
    {
        img: laserOriginal36,
        title:'KKartric HP 35A (CB435A)',
        brand:'HP',
        //:'95.30',
        type: 'laser',
        originality: 'orijinal'
    },
    {
        img: laserOriginal37,
        title:'Kartric HP 36A (CB436A)',
        brand:'HP',
        //:'105.30',
        type: 'laser',
        originality: 'orijinal'
    },
    {
        img: laserOriginal38,
        title:'Kartric HP 42A (Q5942A)',
        brand:'HP',
        //:'295.60',
        type: 'laser',
        originality: 'orijinal'
    },
    {
        img: laserOriginal39,
        title:'Kartric HP 44A (CF244A)',
        brand:'HP',
        //:'99.50',
        type: 'laser',
        originality: 'orijinal'
    },
    {
        img: laserOriginal40,
        title:'Kartric HP 49A (Q5949A)',
        brand:'HP',
        //:'174.52',
        type: 'laser',
        originality: 'orijinal'
    },
    {
        img: laserOriginal41,
        title:'Kartric HP 51A (Q7551A)',
        brand:'HP',
        //:'199.50',
        type: 'laser',
        originality: 'orijinal'
    },
    {
        img: laserOriginal42,
        title:'Kartric HP 53A (Q7553A)',
        brand:'HP',
        //:'165.50',
        type: 'laser',
        originality: 'orijinal'
    },
    {
        img: laserOriginal43,
        title:'Kartric HP 540A (125A)',
        brand:'HP',
        //:'110.00',
        type: 'laser',
        originality: 'orijinal'
    },
    {
        img: laserOriginal44,
        title:'Kartric HP 541A (125A)',
        brand:'HP',
        //:'110.00',
        type: 'laser',
        originality: 'orijinal'
    },
    {
        img: laserOriginal45,
        title:'Kartric HP 542A (125A)',
        brand:'HP',
        //:'110.00',
        type: 'laser',
        originality: 'orijinal'
    },
    {
        img: laserOriginal46,
        title:'Kartric HP 543A (125A)',
        brand:'HP',
        //:'110.00',
        type: 'laser',
        originality: 'orijinal'
    },
    {
        img: laserOriginal47,
        title:'Kartric HP 55A (CE255A)',
        brand:'HP',
        //:'272.00',
        type: 'laser',
        originality: 'orijinal'
    },
    {
        img: laserOriginal48,
        title:'Kartric HP 56A (CF256A)',
        brand:'HP',
        //:'82.50',
        type: 'laser',
        originality: 'orijinal'
    },
    {
        img: laserOriginal49,
        title:'Kartric HP 64A (CC364A)',
        brand:'HP',
        //:'245.50',
        type: 'laser',
        originality: 'orijinal'
    },
    {
        img: laserOriginal50,
        title:'Kartric HP 70A (Q7570A)',
        brand:'HP',
        //:'410.26',
        type: 'laser',
        originality: 'orijinal'
    },
    {
        img: laserOriginal51,
        title:'Kartric HP 78A (CE278A)',
        brand:'HP',
        //:'129.00',
        type: 'laser',
        originality: 'orijinal'
    },
    {
        img: laserOriginal52,
        title:'Kartric HP 80A (CF280A)',
        brand:'HP',
        //:'184.50',
        type: 'laser',
        originality: 'orijinal'
    },
    {
        img: laserOriginal53,
        title:'Kartric HP 81 A (CF281 A)',
        brand:'HP',
        //:'331.00',
        type: 'laser',
        originality: 'orijinal'
    },
    {
        img: laserOriginal54,
        title:'Kartric HP 83A (CE283A)',
        brand:'HP',
        //:'110.33',
        type: 'laser',
        originality: 'orijinal'
    },
    {
        img: laserOriginal55,
        title:'Kartric HP 85A (CE285A)',
        brand:'HP',
        //:'99.50',
        type: 'laser',
        originality: 'orijinal'
    },
    {
        img: laserOriginal56,
        title:'Kartric HP 87A (CF287A)',
        brand:'HP',
        //:'391.00',
        type: 'laser',
        originality: 'orijinal'
    },
    {
        img: laserOriginal57,
        title:'Kartric HP 92A (C4092A)',
        brand:'HP',
        //:'90.27',
        type: 'laser',
        originality: 'orijinal'
    },
    {
        img: laserOriginal58,
        title:'Kartric HP CE 310A (126A)',
        brand:'HP',
        //:'96.00',
        type: 'laser',
        originality: 'orijinal'
    },
    {
        img: laserOriginal59,
        title:'Kartric HP CE 311A (126A)',
        brand:'HP',
        //:'96.00',
        type: 'laser',
        originality: 'orijinal'
    },
    {
        img: laserOriginal60,
        title:'Kartric HP CE 312A (126A)',
        brand:'HP',
        //:'96.00',
        type: 'laser',
        originality: 'orijinal'
    },
    {
        img: laserOriginal61,
        title:'Kartric HP CE 410A (305A)',
        brand:'HP',
        //:'170.50',
        type: 'laser',
        originality: 'orijinal'
    },
    {
        img: laserOriginal62,
        title:'Kartric HP CE 411 (305A)',
        brand:'HP',
        //:'210.50 ',
        type: 'laser',
        originality: 'orijinal'
    },
    {
        img: laserOriginal63,
        title:'Kartric HP CE270A (650A)',
        brand:'HP',
        //:'451.35',
        type: 'laser',
        originality: 'orijinal'
    },
    {
        img: laserOriginal64,
        title:'Kartric HP CE271A (650A)',
        brand:'HP',
        //:'541.68',
        type: 'laser',
        originality: 'orijinal'
    },
    {
        img: laserOriginal65,
        title:'Kartric HP CE272A (650A)',
        brand:'HP',
        //:'541.68',
        type: 'laser',
        originality: 'orijinal'
    },
    {
        img: laserOriginal66,
        title:'Kartric HP CE273A (650A)',
        brand:'HP',
        //:'541.68',
        type: 'laser',
        originality: 'orijinal'
    },
    {
        img: laserOriginal67,
        title:'Kartric HP CE313A (126A)',
        brand:'HP',
        //:'96.00',
        type: 'laser',
        originality: 'orijinal'
    },
    {
        img: laserOriginal68,
        title:'Kartric HP CE314 (126)',
        brand:'HP',
        //:'161.00',
        type: 'laser',
        originality: 'orijinal'
    },
    {
        img: laserOriginal69,
        title:'Kartric HP CE320A (128A)',
        brand:'HP',
        //:'119.36 ',
        type: 'laser',
        originality: 'orijinal'
    },
    {
        img: laserOriginal70,
        title:'Kartric HP CE321A (128A)',
        brand:'HP',
        //:'119.36',
        type: 'laser',
        originality: 'orijinal'
    },
    {
        img: laserOriginal71,
        title:'Kartric HP CE322A (128A)',
        brand:'HP',
        //:'119.36',
        type: 'laser',
        originality: 'orijinal'
    },
    {
        img: laserOriginal72,
        title:'Kartric HP CE323A (128A)',
        brand:'HP',
        //:'119.36',
        type: 'laser',
        originality: 'orijinal'
    },
    {
        img: laserOriginal73,
        title:'Kartric HP CE340 A (651A)',
        brand:'HP',
        //:'330.00',
        type: 'laser',
        originality: 'orijinal'
    },
    {
        img: laserOriginal74,
        title:'Kartric HP CE341 A (651A)',
        brand:'HP',
        //:'625.00',
        type: 'laser',
        originality: 'orijinal'
    },
    {
        img: laserOriginal75,
        title:'Kartric HP CE342 A (651A)',
        brand:'HP',
        //:'625.00',
        type: 'laser',
        originality: 'orijinal'
    },
    {
        img: laserOriginal76,
        title:'Kartric HP CE343 A (651A)',
        brand:'HP',
        //:'625.00',
        type: 'laser',
        originality: 'orijinal'
    },
    {
        img: laserOriginal77,
        title:'Kartric HP CE400 (507A)',
        brand:'HP',
        //:'265.82',
        type: 'laser',
        originality: 'orijinal'
    },
    {
        img: laserOriginal78,
        title:'Kartric HP CE401 (507A)',
        brand:'HP',
        //:'351.15',
        type: 'laser',
        originality: 'orijinal'
    },
    {
        img: laserOriginal79,
        title:'Kartric HP CE402 (507A)',
        brand:'HP',
        //:'351.15',
        type: 'laser',
        originality: 'orijinal'
    },
    {
        img: laserOriginal80,
        title:'Kartric HP CE403 (507A)',
        brand:'HP',
        //:'351.15',
        type: 'laser',
        originality: 'orijinal'
    },
    {
        img: laserOriginal81,
        title:'Kartric HP CE412 (305A)',
        brand:'HP',
        //:'170.50',
        type: 'laser',
        originality: 'orijinal'
    },
    {
        img: laserOriginal82,
        title:'Kartric HP CE413 (305A)',
        brand:'HP',
        //:'170.50',
        type: 'laser',
        originality: 'orijinal'
    },
    {
        img: laserOriginal83,
        title:'Kartric HP CE740A (307A)',
        brand:'HP',
        //:'285.87',
        type: 'laser',
        originality: 'orijinal'
    },
    {
        img: laserOriginal84,
        title:'Kartric HP CE741A (307A)',
        brand:'HP',
        //:'475.50',
        type: 'laser',
        originality: 'orijinal'
    },
    {
        img: laserOriginal85,
        title:'Kartric HP CE742A (307A)',
        brand:'HP',
        //:'475.50',
        type: 'laser',
        originality: 'orijinal'
    },
    {
        img: laserOriginal86,
        title:'Kartric HP CE743A (307A)',
        brand:'HP',
        //:'475.50',
        type: 'laser',
        originality: 'orijinal'
    },
    {
        img: laserOriginal87,
        title:'Kartric HP CE90A (CE390A)',
        brand:'HP',
        //:'318.95',
        type: 'laser',
        originality: 'orijinal'
    },
    {
        img: laserOriginal88,
        title:'Kartric HP Cf210 A (131A)',
        brand:'HP',
        //:'126.30',
        type: 'laser',
        originality: 'orijinal'
    },
    {
        img: laserOriginal89,
        title:'Kartric HP Cf211 A (131A)',
        brand:'HP',
        //:'136.41',
        type: 'laser',
        originality: 'orijinal'
    },
    {
        img: laserOriginal90,
        title:'Kartric HP Cf212 A (131A)',
        brand:'HP',
        //:'136.41',
        type: 'laser',
        originality: 'orijinal'
    },
    {
        img: laserOriginal91,
        title:'Kartric HP Cf213 A (131A)',
        brand:'HP',
        //:'136.41',
        type: 'laser',
        originality: 'orijinal'
    },
    {
        img: laserOriginal92,
        title:'Kartric HP CF350A (CB435A)',
        brand:'HP',
        //:'105.20',
        type: 'laser',
        originality: 'orijinal'
    },
    {
        img: laserOriginal93,
        title:'Kartric HP CF351A (CB435A)',
        brand:'HP',
        //:'105.20',
        type: 'laser',
        originality: 'orijinal'
    },
    {
        img: laserOriginal94,
        title:'Kartric HP CF351A (CB435A)',
        brand:'HP',
        //:'105.20 ',
        type: 'laser',
        originality: 'orijinal'
    },
    {
        img: laserOriginal95,
        title:'Kartric HP CF353A (CB435A)',
        brand:'HP',
        //:'105.20',
        type: 'laser',
        originality: 'orijinal'
    },
    {
        img: laserOriginal96,
        title:'Kartric HP CF380 (312A)',
        brand:'HP',
        //:'174.50',
        type: 'laser',
        originality: 'orijinal'
    },
    {
        img: laserOriginal97,
        title:'Kartric HP CF381 (312A)',
        brand:'HP',
        //:'240.50',
        type: 'laser',
        originality: 'orijinal'
    },
    {
        img: laserOriginal98,
        title:'Kartric HP CF382 (312A)',
        brand:'HP',
        //:'240.50',
        type: 'laser',
        originality: 'orijinal'
    },
    {
        img: laserOriginal99,
        title:'Kartric HP CF383 (312A)',
        brand:'HP',
        //:'240.50',
        type: 'laser',
        originality: 'orijinal'
    },
    {
        img: laserOriginal100,
        title:'Kartric HP CF400 A (201A)',
        brand:'HP',
        //:'110.20',
        type: 'laser',
        originality: 'orijinal'
    },
    {
        img: laserOriginal101,
        title:'Kartric HP CF401 A (201A)',
        brand:'HP',
        //:'120.00',
        type: 'laser',
        originality: 'orijinal'
    },
    {
        img: laserOriginal102,
        title:'Kartric HP CF402 A (201A)',
        brand:'HP',
        //:'120.00',
        type: 'laser',
        originality: 'orijinal'
    },
    {
        img: laserOriginal103,
        title:'Kartric HP CF403 A (201A)',
        brand:'HP',
        //:'120.00',
        type: 'laser',
        originality: 'orijinal'
    },
    {
        img: laserOriginal104,
        title:'Kartric HP CF410 A (410A)',
        brand:'HP',
        //:'170.00',
        type: 'laser',
        originality: 'orijinal'
    },
    {
        img: laserOriginal105,
        title:'Kartric HP CF411 A (410A)',
        brand:'HP',
        //:'210.50',
        type: 'laser',
        originality: 'orijinal'
    },
    {
        img: laserOriginal106,
        title:'Kartric HP CF412A (410A)',
        brand:'HP',
        //:'210.50',
        type: 'laser',
        originality: 'orijinal'
    },
    {
        img: laserOriginal107,
        title:'Kartric HP CF413 A (410A)',
        brand:'HP',
        //:'210.50',
        type: 'laser',
        originality: 'orijinal'
    },
    {
        img: laserOriginal108,
        title:'Kartric HP CF530A (205A)',
        brand:'HP',
        //:'105.50',
        type: 'laser',
        originality: 'orijinal'
    },
    {
        img: laserOriginal109,
        title:'Kartric HP CF531A (205A)',
        brand:'HP',
        //:'110.50',
        type: 'laser',
        originality: 'orijinal'
    },
    {
        img: laserOriginal110,
        title:'Kartric HP CF532A (205A)',
        brand:'HP',
        //:'110.50',
        type: 'laser',
        originality: 'orijinal'
    },
    {
        img: laserOriginal111,
        title:'Kartric HP CF533A (205A)',
        brand:'HP',
        //:'110.50',
        type: 'laser',
        originality: 'orijinal'
    },
    {
        img: laserOriginal112,
        title:'Kartric HP CF540A (203A)',
        brand:'HP',
        //:'124.50',
        type: 'laser',
        originality: 'orijinal'
    },
    {
        img: laserOriginal113,
        title:'Kartric HP CF541A (203A)',
        brand:'HP',
        //:'141.50',
        type: 'laser',
        originality: 'orijinal'
    },
    {
        img: laserOriginal114,
        title:'Kartric HP CF542A (203A)',
        brand:'HP',
        //:'141.50',
        type: 'laser',
        originality: 'orijinal'
    },
    {
        img: laserOriginal115,
        title:'Kartric HP CF543A (203A)',
        brand:'HP',
        //:'141.50 ',
        type: 'laser',
        originality: 'orijinal'
    },
    {
        img: laserOriginal116,
        title:'Katric 117A (W2070A)',
        brand:'HP',
        //:'92.28',
        type: 'laser',
        originality: 'orijinal'
    },
    {
        img: laserOriginal117,
        title:'Katric 117A (W2072A)',
        brand:'HP',
        //:'95.29',
        type: 'laser',
        originality: 'orijinal'
    },
    {
        img: laserOriginal118,
        title:'Katric 117A (W2073A)',
        brand:'HP',
        //:'95.29',
        type: 'laser',
        originality: 'orijinal'
    },
    {
        img: laserOriginal119,
        title:'Katric 415A (W2030A)',
        brand:'HP',
        //:'176.50',
        type: 'laser',
        originality: 'orijinal'
    },
    {
        img: laserOriginal120,
        title:'Katric 415A (W2031A)',
        brand:'HP',
        //:'224.50',
        type: 'laser',
        originality: 'orijinal'
    },
    {
        img: laserOriginal121,
        title:'Katric 415A (W2032A)',
        brand:'HP',
        //:'224.50',
        type: 'laser',
        originality: 'orijinal'
    },
    {
        img: laserOriginal122,
        title:'Katric 415A (W2033A)',
        brand:'HP',
        //:'224.50',
        type: 'laser',
        originality: 'orijinal'
    },
    {
        img: laserOriginal123,
        title:'Katric HP 117A (W2071A)',
        brand:'HP',
        //:'95.29',
        type: 'laser',
        originality: 'orijinal'
    },
    {
        img: laserAnaloq3,
        title:'Kartric CF 540X (203X)',
        brand:'Static Control',
        //:'85.50',
        type: 'laser',
        originality: 'analoq'
    },
    {
        img: laserAnaloq4,
        title:'Kartric CF 541X (203X)',
        brand:'Static Control',
        //:'85.50',
        type: 'laser',
        originality: 'analoq'
    },
    {
        img: laserAnaloq5,
        title:'Kartric CF 542X (203X)',
        brand:'Static Control',
        //:'85.50',
        type: 'laser',
        originality: 'analoq'
    },
    {
        img: laserAnaloq6,
        title:'Kartric CF 543X (203X)',
        brand:'Static Control',
        //:'85.50',
        type: 'laser',
        originality: 'analoq'
    },
    {
        img: laserAnaloq7,
        title:'Kartric CF400X (201X)',
        brand:'Static Control',
        //:'82.50',
        type: 'laser',
        originality: 'analoq'
    },
    {
        img: laserAnaloq8,
        title:'Kartric CF401X (201X)',
        brand:'Static Control',
        //:'82.50',
        type: 'laser',
        originality: 'analoq'
    },
    {
        img: laserAnaloq9,
        title:'Kartric CF402X (201X)',
        brand:'Static Control',
        //:'82.50',
        type: 'laser',
        originality: 'analoq'
    },
    {
        img: laserAnaloq10,
        title:'Kartric CF403X (201X)',
        brand:'Static Control',
        //:'82.50',
        type: 'laser',
        originality: 'analoq'
    },
    {
        img: laserAnaloq11,
        title:'Kartric CF410X (410X)',
        brand:'Static Control',
        //:'89.50',
        type: 'laser',
        originality: 'analoq'
    },
    {
        img: laserAnaloq12,
        title:'Kartric CF411X (410X)',
        brand:'Static Control',
        //:'89.50',
        type: 'laser',
        originality: 'analoq'
    },
    {
        img: laserAnaloq13,
        title:'Kartric CF412X (410X)',
        brand:'Static Control',
        //:'89.50',
        type: 'laser',
        originality: 'analoq'
    },
    {
        img: laserAnaloq14,
        title:'Kartric CF413X (410X)',
        brand:'Static Control',
        //:'89.50',
        type: 'laser',
        originality: 'analoq'
    },
    {
        img: laserAnaloq15,
        title:'Drum Kartric HP 32A (CF232A)',
        brand:'HP',
        //:'39.50',
        type: 'laser',
        originality: 'analoq'
    },
    {
        img: laserAnaloq16,
        title:'Drum Kartric HP 19A (CF219A)',
        brand:'HP',
        //:'39.50',
        type: 'laser',
        originality: 'analoq'
    },
    {
        img: laserAnaloq17,
        title:'Drum Kartric HP CF34A (CF234A)',
        brand:'HP',
        //:'45.50',
        type: 'laser',
        originality: 'analoq'
    },
    {
        img: laserAnaloq18,
        title:'Kartric 35A (CB435A)',
        brand:'HP',
        //:'18.50',
        type: 'laser',
        originality: 'analoq'
    },
    {
        img: laserAnaloq19,
        title:'Kartric Canon 045',
        brand:'Canon',
        //:'35.00',
        type: 'laser',
        originality: 'analoq'
    },
    {
        img: laserAnaloq20,
        title:'Kartric Canon 703',
        brand:'Canon',
        //:'18.50',
        type: 'laser',
        originality: 'analoq'
    },
    {
        img: laserAnaloq21,
        title:'Kartric Canon 712',
        brand:'Canon',
        //:'18.50',
        type: 'laser',
        originality: 'analoq'
    },
    {
        img: laserAnaloq22,
        title:'Kartric Canon 719',
        brand:'Canon',
        //:'22.00',
        type: 'laser',
        originality: 'analoq'
    },
    {
        img: laserAnaloq23,
        title:'Kartric Canon 725',
        brand:'Canon',
        //:'18.50',
        type: 'laser',
        originality: 'analoq'
    },
    {
        img: laserAnaloq24,
        title:'Kartric Canon 728',
        brand:'Canon',
        //:'18.50',
        type: 'laser',
        originality: 'analoq'
    },
    {
        img: laserAnaloq25,
        title:'Kartric Canon 737',
        brand:'Canon',
        //:'18.50',
        type: 'laser',
        originality: 'analoq'
    },
    {
        img: laserAnaloq26,
        title:'Kartric Canon E16',
        brand:'Canon',
        //:'69.50',
        type: 'laser',
        originality: 'analoq'
    },
    {
        img: laserAnaloq27,
        title:'Kartric Canon EP27',
        brand:'Canon',
        //:'22.00',
        type: 'laser',
        originality: 'analoq'
    },
    {
        img: laserAnaloq28,
        title:'Kartric Canon FX10',
        brand:'Canon',
        //:'18.50',
        type: 'laser',
        originality: 'analoq'
    },
    {
        img: laserAnaloq29,
        title:'Kartric HP 30A (CF230A)',
        brand:'HP',
        //:'25.50',
        type: 'laser',
        originality: 'analoq'
    },
    {
        img: laserAnaloq30,
        title:'Kartric HP 30X (CF230X)',
        brand:'HP',
        //:'29.50',
        type: 'laser',
        originality: 'analoq'
    },
    {
        img: laserAnaloq31,
        title:'Kartric HP CF543A (203A)',
        brand:'HP',
        //:'36.50',
        type: 'laser',
        originality: 'analoq'
    },
    {
        img: laserAnaloq34,
        title:'Kartric HP 12A (Q2612A)',
        brand:'HP',
        //:'18.50',
        type: 'laser',
        originality: 'analoq'
    },
    {
        img: laserAnaloq35,
        title:'Kartric HP 13A (Q2613A)',
        brand:'HP',
        //:'22.00',
        type: 'laser',
        originality: 'analoq'
    },
    {
        img: laserAnaloq36,
        title:'Kartric HP 15A (C7115A)',
        brand:'HP',
        //:'21.00',
        type: 'laser',
        originality: 'analoq'
    },
    {
        img: tubaAnaloq1,
        title:'HYB Toner-Kartric EXV-42',
        brand:'HYB',
        //:'28.00',
        type: 'tuba',
        originality: 'analoq'
    },
    {
        img: tubaAnaloq2,
        title:'Toner Canon C-EXV14',
        brand:'Canon',
        //:'19.00',
        type: 'tuba',
        originality: 'analoq'
    },
    {
        img: tubaAnaloq3,
        title:'Toner Canon C-EXV33',
        brand:'Canon',
        //:'90.50',
        type: 'tuba',
        originality: 'analoq'
    },
    {
        img: tubaOrijinal1,
        title:'Canon Toner-Kartric EXV-42',
        brand:'Canon',
        //:'66.50',
        type: 'tuba',
        originality: 'orijinal'
    },
    {
        img: tubaOrijinal2,
        title:'Toner Canon C-EXV 14',
        brand:'Canon',
        //:'70.21 ',
        type: 'tuba',
        originality: 'orijinal'
    },
    {
        img: tubaOrijinal3,
        title:'Toner Canon C-EXV 34 BK',
        brand:'Canon',
        //:'78.28',
        type: 'tuba',
        originality: 'orijinal'
    },
    {
        img: tubaOrijinal4,
        title:'Toner Canon C-EXV 34 CY',
        brand:'Canon',
        //:'165.30',
        type: 'tuba',
        originality: 'orijinal'
    },
    {
        img: tubaOrijinal5,
        title:'Toner Canon C-EXV 34 MG',
        brand:'Canon',
        //:'165.30',
        type: 'tuba',
        originality: 'orijinal'
    },
    {
        img: tubaOrijinal6,
        title:'Toner Canon C-EXV 34 YW',
        brand:'Canon',
        //:'165.30',
        type: 'tuba',
        originality: 'orijinal'
    },
    {
        img: tubaOrijinal7,
        title:'Toner Canon C-EXV 54',
        brand:'HP',
        //:'136.50',
        type: 'tuba',
        originality: 'orijinal'
    },
    {
        img: tubaOrijinal8,
        title:'Toner Canon C-EXV 54 CY',
        brand:'Canon',
        //:'175.00',
        type: 'tuba',
        originality: 'orijinal'
    },
    {
        img: tubaOrijinal9,
        title:'Toner Canon C-EXV 54 MG',
        brand:'Canon',
        //:'175.00',
        type: 'tuba',
        originality: 'orijinal'
    },
    {
        img: tubaOrijinal9,
        title:'Toner Canon C-EXV 54 MG',
        brand:'Canon',
        //:'175.00',
        type: 'tuba',
        originality: 'orijinal'
    },
    {
        img: tubaOrijinal10,
        title:'Toner Canon C-EXV 54 YW',
        brand:'Canon',
        //:'175.00',
        type: 'tuba',
        originality: 'orijinal'
    },
    {
        img: tubaOrijinal11,
        title:'Toner Canon C-EXV33',
        brand:'Canon',
        //:'24.00',
        type: 'tuba',
        originality: 'orijinal'
    }
  ]
